<template>
  <div>
    <el-dialog title="请选择一种大屏展示" :visible.sync="dialog" width="40%" :before-close="handleClose">

      <el-row :gutter="10">
        <el-col :span="12" v-for="(item,index) of list" :key="index">
          <el-card class="box-card" shadow="hover">
            <div slot="header" class="clearfix">
              <span>{{item.title}}</span>
            </div>
            <div>
              <el-image :src="require('../../assets/'+item.picName)"></el-image>
            </div>
            <el-button style="float: right; padding: 10px 0" type="text" @click="selectItem(item.dpName)">选择</el-button>
          </el-card>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import visualConfig from "../../common/visualConfig.js"
//此注册回调是可以自动清理的
import basepage from '../../common/basepage.js'
export default {
  mixins: [basepage],

  data() {
    return {
      list: '',
      dbName: "",
      dialog: false,
    };
  },
  props: {
    //设备数据
    dialogVisible: {
      type: Boolean
    },
  },
  watch: {
    dialogVisible: {
      handler() {
        // console.log(111, '监听值');
        this.dialog = this.dialogVisible
      },
      immediate: true
    },
  },
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
          this.$emit('sure');
        })
        .catch(_ => { });
    },
    //选择
    selectItem(dbName) {
      if (dbName != void 0) {
        let json = {
          "cmd": "setUserDataStore",
          "id": "config",
          "tag": "visualConfig",
          "data": {
            "dbName": dbName
          }
        }

        this.ws.send(json);
        this.dbName = dbName
      }

    },

  },
  created() {
    // console.log('大屏配置页面-----组件创建');
  },
  mounted() {
    this.list = visualConfig.getAllVisualConfig()

    this.on('setUserDataStoreAck', (res) => {
      if (res.error == 0 && res.tag == "visualConfig") {
        this.$store.commit("settingStore/SET_dpNAME", this.dbName);
        this.$message({
          message: '设置成功',
          type: 'success'
        });
      }
      //关闭窗口
      this.$emit('sure');
    })
  },
}
</script>

<style >
.clearfix {
  text-align: center;
}
.el-card__header {
  padding: 14px 20px;
  border-bottom: 0px solid #ebeef5;
}
</style>